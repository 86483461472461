import {AnchorLink} from 'components/AnchorLink';
import React from 'react';

export const serializeHyperlink = (type: string, element: any, content: any) => {
  return (
    <AnchorLink external href={element.data.url} target="_blank">
      {content}
    </AnchorLink>
  );
};
