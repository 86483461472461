import styled from 'styled-components';
import {Link} from 'gatsby-plugin-react-i18next';
import {colors} from '../../global.config';

export const BlogChip = styled(Link)`
  position: relative;
  font-size: 0.8rem;
  color: #ffffff;
  padding: 4px 36px 4px 6px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: ${colors.pink};
  opacity: 0.7;
  top: -24px;
  transition: all 0.3s ease;
  svg {
    position: absolute;
    height: 32px;
    width: 32px;
    right: 0;
    transition: all 0.3s ease;
    path {
      fill: #ffffff;
    }
  }
  &:hover {
    color: #dddddd;
    opacity: 0.9;
    svg path {
      fill: #dddddd;
    }
  }
`;
